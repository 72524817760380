<template>
  <div id="user">
    <el-dialog :title="userFormTitle" width="360px" :visible.sync="userDialogVisible" :close-on-click-modal="false" @close="userDialogClose">
      <!--增加或修改里面的表格-->
      <el-form ref="userFormRef" :model="userForm" :rules="userFormRules" label-position="right" label-width="100px">
        <!--修改里面的内容-->
        <el-form-item label="用户名" prop="username">
          <el-input v-model="userForm.username" placeholder="请输入用户名" clearable />
        </el-form-item>
        <el-form-item label="真实姓名" prop="realName">
          <el-input v-model="userForm.realName" placeholder="请输入真实姓名" clearable />
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="userForm.password" placeholder="请输入密码" show-password clearable />
        </el-form-item>
        <el-form-item label="部门" prop="deptId">
          <el-select-tree v-model="userForm.deptId" placeholder="请选择部门" :data="deptTree" :props="{ value: 'id', label: 'name', children: 'children' }" :disabled-values="[]" :disabled="false" :multiple="false" default-expand-all check-strictly clearable />
        </el-form-item>
        <el-form-item label="角色" prop="roleIds">
          <el-select v-model="userForm.roleIds" placeholder="请选择角色" clearable multiple>
            <el-option v-for="item in roleList" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="userDialogVisible = false"> 取 消 </el-button>
        <el-button type="primary" @click="userFormSubmit"> 确 定 </el-button>
      </div>
    </el-dialog>
    <!--顶部搜索框-->
    <el-row :gutter="20">
      <el-col :span="4">
        <el-input v-model="filterDeptName" prefix-icon="el-icon-search" placeholder="请输入部门名称" size="small" clearable />
        <el-tree ref="deptTreeRef" :data="deptTree" :props="{ label: 'name', children: 'children' }" default-expand-all highlight-current :expand-on-click-node="false" :filter-node-method="deptTreeFilter" @node-click="deptTreeNodeClick" />
      </el-col>
      <el-col :span="20">
        <el-form inline size="small">
          <el-form-item label="用户名">
            <el-input v-model="searchForm.username" placeholder="请输入用户名" clearable />
          </el-form-item>
          <el-form-item label="真实姓名">
            <el-input v-model="searchForm.realName" placeholder="请输入真实姓名" clearable />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="handleSearch"> 搜索 </el-button>
            <el-button v-if="checkPermission(['USER_ADD'])" type="primary" icon="el-icon-plus" @click="handleAdd"> 新增 </el-button>
          </el-form-item>
        </el-form>

        <!--中间展示列表-->
        <el-table :data="userPage.list" row-key="id" :cell-style="{ padding: '6px' }" :header-cell-style="{ background: '#f8f8f9' }" :height="getTableHeight()">
          <el-table-column prop="username" label="用户名" />
          <el-table-column prop="realName" label="真实姓名" />
          <el-table-column prop="deptName" label="部门名称" />
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button v-if="checkPermission(['USER_DELETE'])" type="text" icon="el-icon-delete" size="small" @click.stop="handleDelete(scope.$index, scope.row)"> 删除 </el-button>
              <el-button v-if="checkPermission(['USER_UPDATE'])" type="text" icon="el-icon-edit" size="small" @click.stop="handleUpdate(scope.$index, scope.row)"> 修改 </el-button>
              <el-button v-if="checkPermission(['USER_SELECT'])" type="text" icon="el-icon-info" size="small" @click.stop="handleInfo(scope.$index, scope.row)"> 详情 </el-button>
            </template>
          </el-table-column>
        </el-table>
        <!--页码-->
        <el-pagination :total="userPage.total" :current-page="searchForm.pageNum" :page-size="searchForm.pageSize" :page-sizes="[10, 15, 20]" layout="total, sizes, prev, pager, next, jumper" background @current-change="pageNumChange" @size-change="pageSizeChange" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { addUser, deleteUser, updateUser, selectUserInfo, selectUserList } from '@/api/system/user'
import { selectDeptTree } from '@/api/system/dept'
import { selectRoleList } from '@/api/system/role'

export default {
  data() {
    return {
      userDialogVisible: false,
      userFormTitle: '',
      userForm: {
        id: '',
        username: '',
        password: '',
        realName: '',
        deptId: '',
        roleIds: '',
      },
      userFormRules: {
        username: [{ required: true, message: '用户名不能为空', trigger: ['blur', 'change'] }],
        password: [{ required: true, message: '密码不能为空', trigger: ['blur', 'change'] }],
        realName: [{ required: true, message: '真实姓名不能为空', trigger: ['blur', 'change'] }],
        deptId: [{ required: true, message: '部门不能为空', trigger: ['blur', 'change'] }],
        roleIds: [{ required: true, message: '角色不能为空', trigger: ['blur', 'change'] }],
      },
      userPage: {
        list: [],
        total: 0,
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        deptId: '',
        username: '',
        realName: '',
      },
      deptTree: [],
      filterDeptName: '',
      roleList: [],
    }
  },
  watch: {
    filterDeptName(val) {
      this.$refs.deptTreeRef.filter(val)
    },
  },
  created() {
    selectUserList(this.searchForm).then((res) => {
      this.userPage = res
    })
    selectDeptTree().then((res) => {
      this.deptTree = res
    })
    selectRoleList().then((res) => {
      this.roleList = res
    })
  },
  methods: {
    userDialogClose() {
      this.$refs.userFormRef.resetFields()
    },
    //确认按钮
    userFormSubmit() {
      if (this.userFormTitle === '用户详情') {
        this.userDialogVisible = false
        return
      }
      this.$refs.userFormRef.validate(async (valid) => {
        if (valid) {
          this.userForm.roleIds = this.userForm.roleIds.join(',')
          if (this.userFormTitle === '新增用户') {
            await addUser(this.userForm)
          } else if (this.userFormTitle === '修改用户') {
            await updateUser(this.userForm)
          }
          this.userPage = await selectUserList(this.searchForm)
          this.userDialogVisible = false
        }
      })
    },
    handleAdd() {
      this.userFormTitle = '新增用户'
      this.userDialogVisible = true
    },
    handleDelete(index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning',
      }).then(async () => {
        await deleteUser(row.id)
        if (this.userPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.userPage = await selectUserList(this.searchForm)
      })
    },
    handleUpdate(index, row) {
      this.userFormTitle = '修改用户'
      this.userDialogVisible = true
      this.selectUserInfoById(row.id)
    },
    handleInfo(index, row) {
      this.userFormTitle = '用户详情'
      this.userDialogVisible = true
      this.selectUserInfoById(row.id)
    },
    selectUserInfoById(id) {
      selectUserInfo(id).then((res) => {
        this.userForm.id = res.id
        this.userForm.username = res.username
        this.userForm.password = res.password
        this.userForm.realName = res.realName
        this.userForm.deptId = res.deptId
        this.userForm.roleIds = res.roleList.map((item) => item.id)
      })
    },
    handleSearch() {
      this.searchForm.pageNum = 1
      selectUserList(this.searchForm).then((res) => {
        this.userPage = res
      })
    },
    deptTreeFilter(value, data) {
      if (!value) return true
      return data.name.indexOf(value) !== -1
    },
    deptTreeNodeClick(data) {
      this.searchForm.deptId = data.id
      this.searchForm.pageNum = 1
      selectUserList(this.searchForm).then((res) => {
        this.userPage = res
      })
    },
    pageNumChange(pageNum) {
      this.searchForm.pageNum = pageNum
      selectUserList(this.searchForm).then((res) => {
        this.userPage = res
      })
    },
    pageSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectUserList(this.searchForm).then((res) => {
        this.userPage = res
      })
    },
  },
}
</script>

<style>
#user .el-tree {
  margin-top: 10px;
}
</style>
